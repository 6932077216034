import React from 'react'

import request from '../util/request'

import './rank.css'

class Rank extends React.PureComponent {

  state = {
    rankType: 0,
    rankList: [],
  }

  componentDidMount() {
    this.fetchData(this.state.rankType)
  }

  setRankType = (rankType) => {
    if (rankType !== this.state.rankType) {
      this.setState({rankType, rankList: []})

      this.fetchData(rankType)
    }
  }

  fetchData = (rankType) => {
    request('/user/rank', (result) =>{
      if (result.code === '0') {
        this.setState({
          rankList: result.result
        })
      }
    }, {rankType}, 'GET')
  }

  render () {
    const {rankType, rankList} = this.state;

    return (
      <div className="rank-wrap">
        <div className="rank-type">
          <div className={rankType === 0 ? "rank-tab-sel" : "rank-tab"} onClick={() => this.setRankType(0)}>日榜</div>
          {/* <div className={rankType === 1 ? "rank-tab-sel" : "rank-tab"} onClick={() => this.setRankType(1)}>周榜</div> */}
          <div className={rankType === 2 ? "rank-tab-sel" : "rank-tab"} onClick={() => this.setRankType(2)}>月榜</div>
          <div className={rankType === 3 ? "rank-tab-sel" : "rank-tab"} onClick={() => this.setRankType(3)}>年榜</div>
          <div className={rankType === 4 ? "rank-tab-sel" : "rank-tab"} onClick={() => this.setRankType(4)}>总榜</div>
        </div>
        <div style={{height: 50}}/>
        {rankList.map((v, index) => {
          const {avatar, name, coinToday, coinWeek, coinMonth, coinYear, coinTotal} = v;
          return <div key={v} className="rank-item">
            <div className="rank" style={index < 9 ? {paddingRight: 14} : {}}>{index + 1}</div>
            <img src={avatar} className="rank-avatar" alt=''/>
            <span className="rank-name">{name}</span>
            <div style={{flex: 1}}/>
            <span className="rank-coin">￥
              {rankType === 0 && coinToday}
              {/* {rankType === 1 && coinWeek} */}
              {rankType === 2 && coinMonth}
              {rankType === 3 && coinYear}
              {rankType === 4 && coinTotal}
            <span style={{marginLeft: "2px"}}>币</span></span>
          </div>
        })}
      </div>
    )
  }
}

export default Rank