import React from 'react'

import request from '../util/request'

import './usersPicker.css'

class UsersPicker extends React.PureComponent {

  state = {
    userList: [],
    search: '',
  }

  componentDidMount() {
    request('/user/users', (result) =>{
      this.setState({
        userList: result.result
      })
    })
  }

  handlePicker = (v) => {
    const {callback} = this.props;
    callback && callback(v);
  }

  handleSearch = (e) => {
    this.setState({search: e.target.value})
  }

  render () {
    const { userList, search } = this.state;

    return (
      <div className="picker-wrap">
        <div>
          <input placeholder="输入搜索" onChange={this.handleSearch} className="search" />
        </div>
        <div>
        {userList.filter(v => !search || v.name.indexOf(search) >= 0).map((v) => {
          const {avatar, name, role} = v;
          return <div key={v} className="picker-item" onClick={() => this.handlePicker(v)}>
            <img src={avatar} className="picker-avatar" alt=''/>
            <span className="picker-name">{name}</span>
            <div style={{flex: 1}}/>
            {role === 1 && <div className="picker-role">主管</div>}
          </div>
        })}
        </div>
      </div>
    )
  }
}

export default UsersPicker