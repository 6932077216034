import React from 'react'
import * as dd from 'dingtalk-jsapi';
import request from '../util/request'

import './bonus.css'

class Withdraw extends React.PureComponent {

  state = {
    coin: '',
    selfCoin: 0
  }

  componentDidMount() {
    request('/user/my', (result) => {
      if (result.code === '0') {
        this.setState({selfCoin: result.result.coin});
      }
    })
  }

  handleCoinChange = (e) => {
    this.setState({
      coin: e.target.value
    })
  }

  handleSubmit = () => {
    const {coin} = this.state;

    if (!coin) {
      dd.device.notification.toast({text: '请输入金额'})
      return ;
    }

    request('/coin/withdraw', (result) => {
      if (result.code === '0') {
        dd.device.notification.toast({text: '提交成功'})
        this.props.history && this.props.history.goBack();
      } else {
        dd.device.notification.toast({text: result.message ? result.message : '提交失败'})
      }
    }, {coin}, 'POST')
  }

  render () {
    const { coin, selfCoin } = this.state;

    return (
      <div className="bonus-wrap">
        <div style={{height: 12}}/>
        <div className="coin">
          <span className="sub-title">金额：</span>
          <input type="number" onChange={this.handleCoinChange} value={coin} className="coin-input"/>
          <span className="number-desc">币</span>
        </div>
        <div className="coin-value"><span style={{fontSize: 30}}>￥</span>{coin ? coin : 0}<span className="coin-value-desp">币</span></div>
        <div className="btn-submit" onClick={this.handleSubmit}>提交提现</div>
        <div className="self-coin">当前余额：￥{selfCoin}<span className="self-coin-desp">币</span></div>
      </div>
    )
  }
}

export default Withdraw;