import React from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';

import * as dd from 'dingtalk-jsapi';

import My from './components/my'
import Rank from './components/rank'
import Users from './components/users'
import Bonus from './components/bonus'
import Transfer from './components/transfer'
import Withdraw from './components/withdraw'
import WithdrawList from './components/withdrawList'
import Tab from './components/tab'

import request from './util/request'

import './App.css'

const history = createHashHistory();

dd.ready(function() {
  dd.runtime.permission.requestAuthCode({
      corpId: "dingb42fcb92ff19ffc035c2f4657eb6378f", // 企业id
      onSuccess: function (info) {
        let code = info.code // 通过该免登授权码可以获取用户身份

        request('/sso/dlogin', null, {authCode: code}, 'POST');
      }});
});

class IndexPage extends React.PureComponent {

  componentWillReceiveProps() {
    console.log('IndexPage componentWillReceiveProps')
  }

  render () {
    return (
      <>
        <div style={{flex: 1, overflowY: 'scroll'}}>
          <Switch>
            <Route path='/' exact component={My}/>
            <Route path='/rank' component={Rank}/>
            <Route path='/role' component={Users}/>
            <Route path='/bonus' component={Bonus}/>
            <Route path='/transfer' component={Transfer}/>
            <Route path='/withdraw' component={Withdraw}/>
            <Route path='/withdrawList' component={WithdrawList}/>
          </Switch>
        </div>
        <Tab history={history}/>
      </>
    )
  }
}

function App(props) {
  return (
    <Router history={history}>
      <div className="App">
        <Route path='/' component={IndexPage}/>
      </div>
    </Router>
  );
}

export default App;
