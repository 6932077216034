import React from 'react'
import * as dd from 'dingtalk-jsapi';

import request from '../util/request'
import UsersPicker from './usersPicker'

import './bonus.css'

class Bonus extends React.PureComponent {

  state = {
    selectUser: null,
    coin: '',
    showPicker: 0,
    reason: '',
    ownCoin: 0,
  }

  componentDidMount() {
    request('/user/my', (result) => {
      if (result.code === '0') {
        this.setState({ownCoin: result.result.ownCoin});
      }
    })
  }

  pickerUser = () => {
    this.setState({showPicker: 1})
  }

  handleSelectUser = (v) => {
    this.setState({
      showPicker: 0,
      selectUser: v,
    })
  }

  handleCoinChange = (e) => {
    this.setState({
      coin: e.target.value
    })
  }

  handleReasonChange = (e) => {
    this.setState({
      reason: e.target.value
    })
  }

  handleSubmit = () => {
    const {selectUser, coin, reason} = this.state;
    if (!selectUser) {
      dd.device.notification.toast({text: '请选择用户'})
      return ;
    }
    if (!coin) {
      dd.device.notification.toast({text: '请输入金额'})
      return ;
    }
    if (!reason) {
      dd.device.notification.toast({text: '请输入理由'})
      return ;
    }

    request('/coin/bonus', (result) => {
      if (result.code === '0') {
        dd.device.notification.toast({text: '奖励成功'})
        this.props.history && this.props.history.goBack();
      } else {
        dd.device.notification.toast({text: result.message ? result.message : '奖励失败'})
      }
    }, {userId: selectUser.userid, coin, reason}, 'POST')
  }

  render () {
    const { selectUser, coin, showPicker, reason, ownCoin } = this.state;

    return (
      <div className="bonus-wrap">
        <div style={{height: 12}}/>
        <div className="select-user" onClick={this.pickerUser} >
          <span className="sub-title">{selectUser ? '已选择' : '选择员工'}</span>
          <div style={{flex: 1}}/>
          <span>{selectUser ? selectUser.name : ''}</span>
        </div>
        <div className="coin">
          <span className="sub-title">金额：</span>
          <input type="number" onChange={this.handleCoinChange} value={coin} className="coin-input"/>
          <span className="number-desc">币</span>
        </div>
        <div className="reason">
          <span className="sub-title">理由：</span>
          <textarea onChange={this.handleReasonChange} value={reason} className="reason-input"/>
        </div>
        <div className="coin-value"><span style={{fontSize: 30}}>￥</span>{coin ? coin : 0}<span className="coin-value-desp">币</span></div>
        <div className="btn-submit" onClick={this.handleSubmit}>奖励给Ta</div>
        <div className="own-coin">剩余可奖励：￥{ownCoin}<span className="self-coin-desp">币</span></div>
        {showPicker !== 0 && <UsersPicker callback={this.handleSelectUser}/>}
      </div>
    )
  }
}

export default Bonus