import React from 'react'
import { Link } from 'react-router-dom';

import Icon from './icon'
import request from '../util/request'

import './tab.css'

class Tab extends React.PureComponent {

  state = {
    tabs: [
      {
        name: '我的',
        icon: 'Icon_wode--outline',
        link: '/',
      },
      {
        name: '排行',
        icon: 'paihang',
        link: '/rank'
      },
    ],
    showTab: 1,
    tabIndex: 0
  }

  componentDidMount() {
    request('/user/my', (result) => {
      if (result.code === '0') {
        this.updateTab(result.result.role, result.result.cashier)
      }
    })
  }

  updateTab = (role, cashier) => {
    let tabs = [
      {
        name: '我的',
        icon: 'Icon_wode--outline',
        link: '/'
      },
      {
        name: '排行',
        icon: 'paihang',
        link: '/rank'
      },
    ];

    if (role === -1) {
      tabs.push({
        name: '管理',
        icon: 'quanxian',
        link: '/role'
      });
    } else if (cashier === 1) {
      tabs.push({
        name: '核算',
        icon: 'pause',
        link: '/withdrawList'
      });
    }

    tabs.forEach(v => {
      if (v.link === window.location.pathname) {
        v.selected = 1
      } else {
        v.selected = 0;
      }
    });

    this.setState({tabs});
  }

  handleClickTab = (index) => {
    const {tabs} = this.state;
    
    if (!tabs[index].selected) {
      tabs.forEach(v => v.selected = 0);
      tabs[index].selected = 1;
      this.setState({tabs: [...tabs]})
      this.props.history.replace(tabs[index].link)
    }
  }

  render () {
    const {tabs, showTab} = this.state;

    return (
      showTab && tabs.length ? <div className='tab-wrap'>
        {
          tabs.map((v, index) => {
            const {name, icon, selected} = v;
            return <div key={name} className={selected ? 'tab-select' : 'tab-unselect'}
              onClick={() => this.handleClickTab(index)}>
              <Icon className='tab-icon' name={icon}/>
              <span>{name}</span>
            </div>
          })
        }
      </div> : <></>
    )
  }
}

export default Tab