import React from 'react'
import { Link } from 'react-router-dom'
import * as dd from 'dingtalk-jsapi';

import request from '../util/request'
import {int2DateTime} from '../util/date'
import Icon from './icon'

import './my.css'

class My extends React.PureComponent {
  state = {
    coin: 0,
    ownCoin: 0,
    role: 0,
    historyList: []
  }

  componentDidMount() {
    request('/user/my', (result) => {
      if (result.code === '0') {
        this.setState({...result.result});
      }
    })
    request('/coin/history', (result) => {
      if (result.code === '0') {
        this.setState({historyList: result.result.map(v => {
          const {addtime} = v
          return {...v, addtime: int2DateTime(addtime)}
        })})
      }
    })
  }

  render () {
    const {coin, role, historyList} = this.state;

    return [
      <div className='my-wrap' key='1'>
        <div className='my-coin'>{coin}<span className="flag">币</span></div>
        <div className='my-ops' key='2'>
          {(role > 0 || role === -1) && <Link className='my-item' to="/bonus">
            <Icon name="jiangli" className="my-icon"/>
            <span>奖励</span>
          </Link>}
          <Link className='my-item' to='/transfer'>
            <Icon name="zengsonghuiyuan" className="my-icon"/>
            <span>赠送</span>
          </Link>
          <Link className='my-item' to='/withdraw'>
            <Icon name="pause" className="my-icon"/>
            <span>提现</span>
          </Link>
        </div>
      </div>,
      <div className='my-wrap2' key='3'>
        <div className='sub-title'>最近记录</div>
        {
          historyList.map(v => {
            let type = '奖励';
            let from = '';
            switch(v.type) {
              case 2: 
                type = ''; 
                break;
              case 1: 
                type = '转赠原因：';
                from = v.coin > 0 ? '来自:' + v.fromMemberName + '　' : '赠予:' + v.toMemberName + '　'
                break;
              default:
                type = '奖励原因：';
                from = '来自:' + v.fromMemberName + '　';
            }
            return <div className='history-item' key={v}>
                <div className='content'>
                  <div className='title'>{type}{v.reason}</div>
                  <div className='time'>{from}{v.addtime}</div>
                </div>
                <div className={v.coin > 0 ? 'change' : 'change sub'}>
                  {v.coin > 0 ? '+' : ''}{v.coin}
                </div>
              </div>
          })
        }
      </div>
    ]
  }
}

export default My