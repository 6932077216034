import React from 'react'
import cn from 'classnames';

class Icon extends React.PureComponent {
  render() {
    const {name, title, className, ...other} = this.props;
    
    return title ? (
      <div title={title}>
        <svg
          className={cn('icon', className)}
          aria-hidden="true"
          {...other}
        >
          <use xlinkHref={`#icon${name}`}/>
        </svg>
      </div>
    ) : (
      <svg
        className={cn('icon', className)}
        aria-hidden="true"
        {...other}
      >
        <use xlinkHref={`#icon${name}`}/>
      </svg>
    );
  }
}

export default Icon;