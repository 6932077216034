import React from 'react'
import * as dd from 'dingtalk-jsapi';

import request from '../util/request'
import {int2DateTime} from '../util/date'

import './withdrawList.css'

class WithdrawList extends React.PureComponent {

  state = {
    withdrawList: [],
  }

  componentDidMount() {
    request('/coin/applyList', (result) => {
      if (result.code === '0') {
        this.setState({withdrawList: result.result.map(v => {
          v.addtime = int2DateTime(v.addtime);
          if (v.updateTime) {
            v.updateTime = int2DateTime(v.updateTime);
          }
          return v;
        })})
      }
    })
  }

  handleConfirm = (index) => {
    const {withdrawList} = this.state;

    request('/coin/applyWithdraw', (result) => {
      if (result.code === '0') {
        withdrawList[index].status = 1;
        this.setState({withdrawList: [...withdrawList]})
      }
    }, {cashId: withdrawList[index].id, status: 1}, 'POST')
  }

  render () {
    const {withdrawList} = this.state;

    return (
      <div className="withdraw-wrap">
        {withdrawList && withdrawList.map((v, index) => {
          const {id, member: {avatar, name}, coin, status, addtime, updateTime} = v;
          return <div key={id} className="withdraw-item">
              <img src={avatar} className="withdraw-avatar" alt=''/>
              <div>
                <div className="withdraw-name">{name}</div>
                <div className="withdraw-time">{addtime.substring(5, addtime.length - 3)}</div>
              </div>
              <div style={{flex: 1}}/>
              <div className="withdraw-coin">￥{coin}币</div>
              {status === 0 && <div className="withdraw-confirm" 
                onClick={() => this.handleConfirm(index)}>确认核销</div>}
              {status !== 0 && <div className="withdraw-confirmed">
                  <div>已核销</div>
                  <div className="time">{updateTime.substring(5, updateTime.length - 3)}</div>
                </div>}
            </div>
        })}
      </div>
    )
  }
}

export default WithdrawList