import config from '../config.js'
import qs from 'querystringify';

const HOST = config.host;

let token = '';

const request = (api, callback, param, method) => {
  if (api !== '/sso/dlogin' && !token) {
    let checkInterval = setInterval(
      () => {
        if (token) {
          clearInterval(checkInterval)
          request(api, callback, param, method)
        }
      }, 500
    )
    return;
  }

  let headers = {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    'Access-Control-Allow-Origin': '*',
    Authorization: 'Bearer' + token
  };
  fetch(HOST + '/coinapi' + api + (method !== 'POST' && param ? '?' + qs.stringify(param) : ''),
    {
      method: method ? method : 'GET',
      headers,
      mode: 'cors',
      body: param && method === 'POST' ? qs.stringify(param) : null,
  })
    .then(res => res.json())
    .then(result => {
        callback && callback(result);
        if (api === '/sso/dlogin'
          && result.code === '0'
          && result.result) {
          token = result.result.token;
        }
    })
}

export default request;