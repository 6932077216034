import React from 'react'
import * as dd from 'dingtalk-jsapi';

import request from '../util/request'

import './users.css'

class Users extends React.PureComponent {
  
  state = {
    userList: [],
    search: '',
    oldCoin: '',
    coin: ''
  }

  componentDidMount() {
    request('/user/users', (result) =>{
      this.setState({
        userList: result.result
      })
    })
  }

  handleEdit = (index) => {
    const {userList} = this.state;
    userList.forEach(v => {
      v.edit = 0
    })
    userList[index].edit = 1;

    this.setState({
      userList: [...userList],
      oldCoin: userList[index].ownCoin,
      coin: ''
    })
  }

  handleCoinChange = (e) => {
    this.setState({
      coin: e.target.value
    })
  }

  handleEditSubmit = (index) => {
    const {userList, coin} = this.state;

    if (! coin) {
      dd.device.notification.toast({text: '请输入金额'})
      return;
    }

    request('/admin/add-own', (result) => {
      if (result.code === '0') {
        userList[index].edit = 0;
        userList[index].ownCoin = result.result.ownCoin;
        this.setState({userList: [...userList]})
      } else {
        dd.device.notification.toast({text: result.message ? result.message : '失败'})
      }
    }, {userId: userList[index].userid, own: coin}, 'POST');
  }

  handleSetRole = (index, role) => {
    const {userList} = this.state;

    request('/admin/set-role', (result) => {
      if (result.code === '0') {
        userList[index].role = role ? 1 : 0
        this.setState({
          userList: [...userList]
        })
      }
    }, {userId: userList[index].userid, role: role ? 1 : 0}, 'POST')
  }

  handleSearch = (e) => {
    this.setState({search: e.target.value})
  }

  render () {
    const {userList, search, oldCoin, coin} = this.state;

    return (
      <div className="users-wrap">
        <div>
          <input placeholder="输入搜索" onChange={this.handleSearch} className="search" />
        </div>
        <div>
          {userList.filter(v => !search || v.name.indexOf(search) >= 0).map((v) => {
            const {name, avatar, role, ownCoin, edit} = v;
            const index = userList.indexOf(v);
            return <div key={v} className="users-item" onClick={() => this.handlePicker(v)}>
                <img src={avatar} className="users-avatar" alt=''/>
                <span className="users-name">{name}</span>
                <div style={{flex: 1}}/>
                {role > 0 && edit !== 1 && <div>￥{ownCoin}</div>}
                {role > 0 && edit === 1 && <input value={coin} className="owncoin-input" 
                  placeholder={oldCoin} type="number" onChange={this.handleCoinChange}/>}
                {edit === 1 && <div className="users-btn" onClick={() => this.handleEditSubmit(index)}>完成</div>}
                {edit !== 1 && role > 0 && <div className="users-btn" onClick={() => this.handleEdit(index)}>编辑</div>}
                {edit !== 1 && role > 0 && <div className="users-btn" onClick={() => this.handleSetRole(index, 0)}>取消主管</div>}
                {edit !== 1 && role === 0 && <div className="users-btn" onClick={() => this.handleSetRole(index, 1)}>设为主管</div>}
              </div>
          })}
        </div>
      </div>
    )
  }
}

export default Users;